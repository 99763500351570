.paco-logs-modal {

    &__zero-modal-content,
    &__zero-logs {
        height: 90svh;
    }

    &__detail-logs {
        padding-inline: 20px;
        padding-block: 10px;
    }
}