.pz-log-data-table {

    &__value-cell {
        max-width: inherit;
        width: fit-content;
    }

    &__key-cell {
        width: unset;
        max-width: unset;
    }

    &__key-cell-content {
        font-weight: var(--fw-bold);
    }
}
