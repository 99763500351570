@import "src/@paco/styles";

.shifts-calendar-filter-container {
    position: relative;

    &__add-shift-button {
        @extend %button--is-primary;

        width: calc(100% - #{remCalc(2.5)});
        font-size: remCalc(.9375);
        margin: remCalc(1.25);
    }
}
