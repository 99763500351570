@import "src/@paco/styles";

.pincode {
    max-width: remCalc(30);
    text-align: center;

    &__title {
        font-size: remCalc(1.25);
        font-weight: var(--fw-medium);
        color: var(--c-primary);
    }

    &__contact,
    &__logout-button {
        margin-top: remCalc(2);
    }

    &__numpad-display,
    &__numpad-input {
        margin-top: 2rem;
    }

    &__numpad {
        padding-block: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__error-message {
        margin-bottom: 0;
        line-height: 2.4;
        color: var(--c-red);
    }

    &__contact {
        margin-top: .5rem;
    }

    &__logout-button {
        @extend %button--is-primary;

        margin-inline: auto;
    }
}
