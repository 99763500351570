@import "src/@paco/styles";

.paco-detail-logs {
    &__logs {
        display: none;
        padding: 20px;
    }

    &__log {
        position: relative;
        display: flow;
        padding: unset;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -22px;
            width: 1px;
            height: 100%;
            background: var(--c-cyan);
        }

        &:first-child {
            .paco-detail-logs__title {
                padding-block-start: unset;
            }

            &::before {
                top: 50%;
                height: 50%;
            }
        }

        &:last-child {
            &::before {
                top: 0;
                height: 50%;
            }

            &::after,
            &:first-child::before {
                display: none;
            }
        }
    }

    &__circle {
        position: absolute;
        left: -30px;
        top: calc(50% - 7px);
        box-sizing: border-box;
        height: 17px;
        width: 17px;
        border: 1px solid var(--c-cyan);
        border-radius: 50%;
        background: var(--c-white);

        &::after {
            position: absolute;
            content: '';
            top: 2px;
            left: 2px;
            width: 11px;
            height: 11px;
            background: var(--c-cyan);
            border-radius: 50%;
        }
    }

    &__title {
        display: flex;
        justify-content: space-between;
        padding-block-start: 1rem;
        padding-block-end: 1rem;
    }

    &__bold {
        font-weight: var(--fw-medium);
    }

    &__subrow {
        display: inline-table;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__value-cell {
        font-weight: var(--fw-medium);
    }

    &__value-cell-change {
        width: unset;
        max-width: unset;
        border-left: unset;

        @include for-size(tablet-portrait-up) {
            border-left: 1px solid var(--c-border-grey);
        }
    }

    &__content-value-change {
        overflow: auto;
    }

    &__date-button {
        &--is-active + .paco-detail-logs__logs {
            display: block;
        }

        &--is-active .paco-detail-logs__date-button-icon {
            transform: rotate(180deg);
        }
    }
}
