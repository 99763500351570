@import "../../../style/theme.scss";

.form-absence {
    background: red;

    &-full-day {
        display: none;
    }

    &-hours-overview {
        display: flex;
        justify-content: space-between;
        padding: 20px 60px 20px 40px;
        background: $light-grey;
    }

    &-hours-title {
        font-weight: 600;
    }

    &-remark {
        margin-bottom: 30px;
    }

    &-button-reject {
        display: flex;
        justify-content: center;
    }

    &--full-day {
        .form-absence-part-day {
          display: none;
        }

        .form-absence-full-day {
          display: block;
        }
    }

    &__start-date,
    &__start-time {
        white-space: nowrap;
    }
}

.form-edit-shift {
    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    &-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-select-users {
        margin-bottom: 10px;
    }

    &-planned-users {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;
    }

    &-planned-users + .planned-user-card {
        border-top: 1px solid $border-grey;
    }

    &-disable-replacement {
        margin-bottom: 30px;
    }

    &-planned-users {
        position: relative;
    }
}

.resolve-leave-of-absence {
    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.leave-of-absence-form {
    &__row {
        width: 100%;
    }

    &__col {
        margin-bottom: remCalc(1);
    }

    &__table-button {
        @include clear-button();
    }

    &__table-button-icon {
        color: $light-blue;
    }
}
